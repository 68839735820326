document.addEventListener('DOMContentLoaded', function (e) {

    $('#eyeCustomerPassword').on('click', function () {

        if ($(this).hasClass('bi bi-eye-slash')) {
            $(this).removeClass('bi bi-eye-slash');
            $(this).addClass('bi bi-eye');
            $('#customer_plainPassword').attr('type', 'text');
        } else {
            $(this).removeClass('bi bi-eye');
            $(this).addClass('bi bi-eye-slash');
            $('#customer_plainPassword').attr('type', 'password');
        }
    });

    const customer_registration = document.getElementById('customer_registration');

    let fv = FormValidation.formValidation(
        customer_registration,
        {
            fields: {
                'customer[firstName]': {
                    validators: {
                        notEmpty: {
                            message: 'Le prénom est requis'
                        },
                    }
                },
                'customer[lastName]': {
                    validators: {
                        notEmpty: {
                            message: 'Le nom est requis'
                        },
                    }
                },

                'customer[phoneNumber]': {
                    validators: {
                        notEmpty: {
                            message: 'Le numéro de téléphone est requis'
                        },
                    }
                },
                'customer[email]': {
                    validators: {
                        notEmpty: {
                            message: "L'email est requise"
                        },
                        emailAddress: {
                            message: 'Veuillez entrer une adresse email valide',
                        },
                        callback: {
                            message: "La valeur saisie n'est pas une adresse email valide",
                            callback: function (input) {
                                const value = input.value;
                                if (value === '') {
                                    return true;
                                }

                                // I want the value has to pass both emailAddress and regexp validators
                                return (
                                    FormValidation.validators.emailAddress().validate({
                                        value: value,
                                    }).valid &&
                                    FormValidation.validators.regexp().validate({
                                        value: value,
                                        options: {
                                            regexp: '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$',
                                        },
                                    }).valid
                                );
                            },
                        },
                    }
                },
                'customer[plainPassword]': {
                    validators: {
                        notEmpty: {
                            message: 'Le mot de passe est requis'
                        },
                    }
                },
            },

            plugins: {
                trigger: new FormValidation.plugins.Trigger(),

                bootstrap: new FormValidation.plugins.Bootstrap5({
                    rowSelector: '.fv-row',
                    eleInvalidClass: '',
                    eleValidClass: '',

                }),
                internationalTelephoneInput: new FormValidation.plugins.InternationalTelephoneInput({
                    field: 'customer[phoneNumber]',
                    message: "Le numéro de téléphone n'est pas valide",
                    autoHideDialCode: true,
                    nationalMode: false,
                    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js',
                }),
                excluded: new FormValidation.plugins.Excluded(),
            }
        }
    );

// Submit button handler
    const btnCustomerSubmit = document.querySelector('#btnCustomerSubmit');
    btnCustomerSubmit.addEventListener('click', function (e) {
        // Prevent default button action

        e.preventDefault();

        // Validate form before submit
        if (fv) {
            fv.validate().then(function (status) {


                if (status === 'Valid') {
                    // Show loading indication
                    btnCustomerSubmit.setAttribute('data-kt-indicator', 'on');

                    // Disable button to avoid multiple click
                    btnCustomerSubmit.disabled = true;

                    // Simulate form submission. For more info check the plugin's official documentation: https://sweetalert2.github.io/
                    setTimeout(function () {
                        // Remove loading indication
                        btnCustomerSubmit.removeAttribute('data-kt-indicator');

                        // Enable button
                        btnCustomerSubmit.disabled = false;
                        Swal.fire({
                            title: "Confirmation",
                            text: "Etes-vous sûr de vouloir effctuer cette action ?",
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonColor: '#13c9f0',
                            cancelButtonColor: 'rgba(221,51,51,0.73)',
                            confirmButtonText: '<i class="bi bi-hand-thumbs-up"></i> Oui',
                            cancelButtonText: '<i class="bi bi-hand-thumbs-down"></i> Non',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                customer_registration.submit();
                            }
                        })
                    }, 1000);
                }else{
                    btnCustomerSubmit.disabled = false;
                    Swal.fire({
                        text: "Les champs avec une étoile (*) sont obligatoires",
                        icon: "error",
                        buttonsStyling: true,
                        confirmButtonText: "Corriger les erreurs",
                        customClass: {
                            confirmButton: "btn btn-danger"
                        }
                    })
                }
            });
        }

    });

    const phoneInput = document.getElementById('customer_phoneNumber');
    const form = document.getElementById('customer_registration');
    const phoneError = document.getElementById('phoneError');
    const submitButton = document.getElementById('btnCustomerSubmit');

    phoneInput.addEventListener('keyup', function() {
        const phoneNumber = phoneInput.value;
        const isValid = isValidPhoneNumber(phoneNumber);
        if (isValid) {
            phoneInput.setCustomValidity('');
            phoneInput.classList.remove('is-invalid');
            phoneError.innerHTML = '';
            submitButton.disabled = false;
        } else {
            submitButton.disabled = true;
            phoneInput.setCustomValidity('Invalid');
            phoneInput.classList.add('is-invalid');
            phoneError.innerHTML = 'Veuillez saisir un numéro de téléphone valide.';
        }
    });

    form.addEventListener('submit', function(event) {
        event.preventDefault();
        const phoneNumber = phoneInput.value;
        const isValid = isValidPhoneNumber(phoneNumber);
        if (isValid) {
            // Le numéro de téléphone est valide, vous pouvez soumettre le formulaire ici
            submitButton.disabled = false;
            // form.submit();
        } else {
            // Le numéro de téléphone n'est pas valide, afficher un message d'erreur ou effectuer d'autres actions
            submitButton.disabled = true;
        }
    });
    function isValidPhoneNumber(phoneNumber) {

        const phonePattern = /^\+?\d{1,}$/; // Expression régulière pour le numéro de téléphone
        return phonePattern.test(phoneNumber);
    }
});